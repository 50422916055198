<label class="form-label" for="" {...$$restProps}>
    <slot />
</label>

<style>
    .form-label {
		font-size: 14px;
		margin-bottom: 10px;
		display: inline-block;
	}
</style>