<script>
	export let onChange = () => {};
	export let onInput = () => {};
</script>

<input type="text" class="form-input" {...$$restProps} on:change={ onChange } on:input={ onInput } />

<style lang="scss">
    .form-input {
		border: 1px solid var(--background-input);
		background-color: var(--background-bg);
		padding: 10px 15px;
		font-size: 15px;
		font-weight: 500;
		width: 100%;
		border-radius: 6px;

		&:focus-visible,
		&.focus {
			background-color: white;
			outline: 2px solid blue;
		}
	}

	.form-input[readonly],
	.form-input[disabled] {
		background-color: #e9ecef;
	}
</style>