<script>
	export let onSubmit;
</script>

<form { ...$$restProps } on:submit={ onSubmit }>
	<slot />
</form>

<style>
	form {
		display: flex;
		gap: 25px;
		flex-direction: column;
	}
</style>